jQuery(document).ready(function($){
    $('.cust').addClass('hidden');
    $('.tech').addClass('hidden');

    if($('input:radio[name=user_type]:checked').val() == 'customer')
    {
        $('.company_name').removeClass('hidden');
        $('.cust').removeClass('hidden');
        $('.tech').addClass('hidden');
    }
    if($('input:radio[name=user_type]:checked').val() == 'technician'){
        $('.company_name').addClass('hidden');
        $('.cust').addClass('hidden');
        $('.tech').removeClass('hidden');
        
    }
	
	$('input[type=radio][name=user_type]').change(function() {
        hideunhide_companyname(this.value);
    });


    // Validation Registration //
    //alert('in');
   
    

    /*$('#login').bootstrapValidator({
        
        fields: {
            login: {
                validators: {
                    notEmpty: {
                        message: 'Please enter email address'
                    }
                    // emailAddress: {
                    //     message: 'Please enter a valid email address'
                    // }
                }
            },

            password: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter a password'
            		}
            	}
            }
        }
    });
*/
/*        $('#resetPassword').bootstrapValidator({
        
        fields: {
            email: {
                validators: {
                    notEmpty: {
                        message: 'Please enter email address'
                    },
                    emailAddress: {
                        message: 'Please enter a valid email address'
                    }
                }
            }
        }
    });*/

    

    $('input[name="univ-id"]').keypress(function (e) {
       //if the letter is not digit then display error and don't type anything
       if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          //display error message
          $(".errmsg").html("Number Only").show().fadeOut(3000);
                 return false;
      }
    });


    $('#resetPasswordConfirm').bootstrapValidator({
       	fields: {
            email: {
                validators: {
                    notEmpty: {
                        message: 'Please enter email address'
                    },
                    emailAddress: {
                        message: 'Please enter a valid email address'
                    }
                }
            },

            password: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter a password'
            		},
                	identical: {
                    	field: 'confirmPassword',
                    	message: 'Confirm your password below - type same password please'
                	}
            	}
        	},
        	password_confirmation: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter Confirm password'
            		},
                	identical: {
                    	field: 'password',
                    	message: 'The password and its confirm are not the same'
                	}
            	}
         	}
        }

        });

        $('#changePassword').bootstrapValidator({
       	fields: {
       		old_password: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter old password'
            		}
            	}
        	},

            password: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter a password'
            		},
                	identical: {
                    	field: 'confirmPassword',
                    	message: 'Confirm your password below - type same password please'
                	}
            	}
        	},
        	password_confirmation: {
            	validators: {
            		notEmpty: {
            			message: 'Please enter Confirm password'
            		},
                	identical: {
                    	field: 'password',
                    	message: 'The password and its confirm are not the same'
                	}
            	}
         	}
        }

        });
	
});

jQuery.validator.addMethod("lettersonly", function(value, element) {
    return this.optional(element) || /^[a-z\s]+$/i.test(value);
}, "Only alphabetical characters"); 

jQuery.validator.addMethod("alphaNumeric", function(value, element) {
  return this.optional( element ) || /^[a-zA-Z0-9\-\s]+$/.test( value );
}, 'Special characters not allowed.');




 $.validator.addMethod("needsSelection", function (value, element) {
        var count = $(element).find('option:selected').length;
        return length > 0;
    },"Only alphabetical characters");


jQuery.validator.addMethod("validEmail", function(value, element) {
  return this.optional( element ) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
},"Please enter a valid email address");

jQuery(function($){

    /* Lodaing Overlay append it to body */
    
   // $('<div id=overlay><img id="loading" src="{{ asset("/images/pageloader.gif") }}" height="50px" width="50px"></div>').prependTo(document.body);  

     $("#registration").validate({

         rules: {
            first_name: {
               required: true,
               lettersonly: true,
               minlength: 4
            },
            

            last_name:{
               required: true,
               lettersonly: true,
            }, 
            
            email: {
               required: true,
               validEmail : true
            },
            
            password: {
              required : true,
              minlength : 6,
            },

            password_confirmation: {
               required : true,
               minlength : 6,
               equalTo : "#password"
            },
            
            terms: {
               required: true,
            },

            company_name: {
               required: true,
            },

            user_type: "required",


         },

        messages: {
            first_name: {
                required : "First Name field is required",
                minlength : "please enter atleast 4 Character",
            },
            last_name : {
                required : "Last Name field is required",
            },
            email : {
                required : "Email field is required",
            },
            password : {
                required : "Password field is required",
            },
            password_confirmation: {
               required: "Password Confirm field is required" 
            },
            terms : {
                required : "Please accept our Terms and Conditions"
            },
            company_name: {
               required: "Please enter company name",
            },
            user_type: {
               required: "Please select atleast one User Type",
            },


                
        },

        errorPlacement: function(error, element) {
           if (element.attr("name") == "user_type") {
                error.insertAfter('#requestRadio1');
           }else if(element.attr("type")=='checkbox'){ error.insertAfter($(element).parent()); }
            else if(element.attr("name")=='first_name'){ error.insertAfter('#first_name_error'); }
            else if(element.attr("name")=='last_name'){ error.insertAfter('#last_name_error'); }
            else if(element.attr("name")=='email'){ error.insertAfter('#email_error'); }
            else if(element.attr("name")=='company_name'){ error.insertAfter('#company_name_error'); }
            else if(element.attr("name")=='password'){ error.insertAfter('#password_error'); }
            else if(element.attr("name")=='password_confirmation'){ error.insertAfter('#re_password_error'); }
           else {
            error.insertAfter(element);
           }
        },
         
     });
    

    $("#login").validate({

         rules: {
            
            login: {
               required: true,
            },
            
            password: {
              required : true,
              minlength : 6,
            },

         },

        messages: {
            
            login : {
                required : "Login Email field is required",
            },
            password : {
                required : "Password field is required",
            },
                
        },

        errorPlacement: function(error, element) {
           if(element.attr("name")=='login'){ error.insertAfter('#login_error'); }
           else if(element.attr("name")=='password'){ error.insertAfter('#password_error'); }
           else {
            error.insertAfter(element);
           }
        },
         
     });

    $("#resetPassword").validate({

         rules: {
            
            email: {
               required: true,
               validEmail : true
            },
         },

        messages: {
            
            email : {
                required : "Login Email field is required",
            },     
                
        },

        errorPlacement: function(error, element) {
           if (element.attr("name") == "email") {
                error.insertAfter('#email_error');
            }
           else {
            error.insertAfter(element);
           }
        },
         
     });

});

function hideunhide_companyname(userType)
{
	if(userType == 'customer'){
		$('.company_name').removeClass('hidden');
    $('.cust').removeClass('hidden');
    $('.tech').addClass('hidden');
	}
	else
	{
		$('.company_name').addClass('hidden');
    $('.cust').addClass('hidden');
    $('.tech').removeClass('hidden');
	}
}

    $("select").on("select2:close", function (e) {  
        $(this).valid(); 
    });





